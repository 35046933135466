/* Estilos generales */
.ubicacion-page {
    padding: 50px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

h1, h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

p {
    text-align: center;
    color: #555;
}

/* Sección de información de ubicación */
.ubicacion-info {
    margin-bottom: 40px;
}

.mapa-section {
    margin-top: 20px;
    max-width: 100%;
    height: auto;
}

/* Estilos para el iframe de Google Maps */
.mapa-section iframe {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
