/* Estilo general para la página */
.home-page {
  padding: 0 20px;
  font-family: Arial, sans-serif;
}

/* Barra de reservas fija */
.sticky-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

/* Sección de video */
.video-section {
  margin-top: 90px;  /* Espacio para el cintillo */
  position: relative;
  height: 100vh;  /* Ocupa toda la pantalla */
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);  /* Oscurece el video */
}

/* Sección de Habitaciones y Amenidades */
.amenities-section {
  padding: 50px 0;
}

.amenity svg {
  margin-right: 15px;  /* Espacio entre el ícono y el texto */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Detalles de Habitaciones (RoomTypes) */
.room-info h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

/* Sección de Reviews */
.reviews-section {
  padding: 50px 0;
}

/* FAQ */
#faq {
  padding: 50px 0;
  max-width: 1000px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
}

#faq h2 {
  text-align: center;
  color: #333;
  margin-bottom: 40px;
}

/* Contacto */
.contact-page {
  padding: 50px 0;
  text-align: center;
}

.contact-page h1 {
  margin-bottom: 20px;
}

/* Estilo de barra de reservas (Cintillo fijo) */
.cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.cta-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cta-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #0056b3;
}
/* Estilos para la barra fija (sticky-bar) */
.sticky-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  z-index: 1000;
}

.hotel-name h1 {
  font-size: 30px;
  font-weight: bold;
  color: #1D4467;
  
}

.icon-center {
  position: absolute;
  top: 20px; /* Ajusta este valor para que el ícono esté sobre la barra */
  left: 44%;
  z-index: 1001; 

}

.favicon-icon {
  width: 130px;
  height: 130px;
}

.reserve-btn-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
}


@media (max-width: 768px) {
  .reserve-btn-container {
    justify-content: flex-start;  
    padding-right: 200px;
    padding-right: 100px; 
 
  }
}


.form-toggle-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.form-toggle-btn:hover {
  background-color: #45a049;
}

.embedded-form {
  margin: 20px;
  text-align: center;
}

.embedded-form iframe {
  margin-top: 60px; 
  margin-bottom: 30px;}

  .section-divider {
    border: 1px solid #ccc;
    margin: 40px 0;
  }