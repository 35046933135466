/* Sección general de CTA */
.cta-section {
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;  /* Centra el contenedor */
    align-items: center;
}

.cta-container {
    width: 60%;  /* Limita el ancho del contenedor al 60% */
    background-color: #fff;  /* Color de fondo del CTA */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);  /* Sombra suave para darle profundidad */
    border-radius: 12px;  /* Bordes redondeados */
    padding: 20px;  /* Espaciado interno */
    display: flex;
    flex-direction: column;  /* Organiza los elementos en dos filas */
}

/* Primera fila con los campos del formulario */
.cta-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;  /* Espaciado exacto de 30px entre los componentes */
    margin-bottom: 20px;  /* Añade espacio entre las dos filas */
}

/* Estilos para los campos de entrada */
.cta-input {
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    flex: 1;  /* Todos los inputs toman el mismo espacio dentro del contenedor */
}



.cta-input:focus {
    border-color: #007bff;
    outline: none;
}

/* Estilos para el DatePicker */


.react-datepicker__input-container input {
    width: 96%;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #ccc;
    outline: none;
}

/* Estilos para el botón */
.cta-button {
    padding: 12px 25px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    flex: 1;  /* El botón toma el mismo espacio que los inputs */
}

.cta-button:hover {
    background-color: #0056b3;
}

/* Segunda fila con información adicional */
.cta-extra-info {
    display: flex;
    justify-content: space-between;
    width: 100%;  /* Ocupa todo el ancho del contenedor */
    padding: 10px 0;
    background-color: #333;
    color: white;
    border-radius: 0 0 12px 12px;  /* Bordes redondeados en la parte inferior */
}

.cta-extra-info span {
    font-size: 14px;
    font-weight: bold;
    text-align: center; /* Centra el texto */
    flex: 1;  /* Distribuye el espacio de manera uniforme entre las secciones */
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
    .cta-container {
        width: 90%;  /* Ajusta el ancho a 90% en pantallas pequeñas */
    }

    .cta-form {
        flex-direction: column;
        gap: 15px;
    }

    .cta-button {
        width: 100%;
    }

    .cta-extra-info {
        flex-direction: column;
        text-align: center;
    }
}
