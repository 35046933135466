.amenities-section {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    color: #333;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .amenities-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .amenity {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

  .amenity svg {
    margin-right: 10px;  /* Espacio entre el ícono y el texto */
  }
  
  .amenity i {
    margin-right: 10px;
    font-size: 24px;
    color: #5a5a5a;
  }
  
  .see-more {
    color: #0066c0;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    font-size: 16px;
  }
  
  .hotel-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  .info-item svg {
    margin-right: 10px;
  }
  
  .info-item i {
    margin-right: 1px;
    font-size: 24px;
    color: #5a5a5a;
  }
  
  .accessibility {
    color: #0066c0;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    font-size: 16px;
  }
  
  /* Adaptación para pantallas pequeñas */
  @media (max-width: 768px) {
    .amenities-grid,
    .hotel-info {
      grid-template-columns: 1fr;
    }
  }