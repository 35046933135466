/* Estilos generales */
.contact-page {
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

h1, h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.contact-details h3 {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}

/* Asegura el margen entre los íconos y el texto */
.contact-details h3 svg {
  margin-right: 10px;
}

p {
  text-align: center;
  color: #555;
}

/* Sección de información de contacto */
.contact-info {
  margin-bottom: 40px;
}

.contact-details {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap;  /* Permitir que los elementos se ajusten en dispositivos móviles */
}

.contact-details div {
  text-align: center;
  display: flex;
  align-items: center;  /* Alinear los íconos verticalmente con el texto */
  margin-bottom: 10px;  /* Evitar que los elementos se superpongan en pantallas pequeñas */
}

.contact-details i {
  margin-right: 8px;  /* Espacio entre el ícono y el texto */
}

.contact-details a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
}

.contact-details a:hover {
  text-decoration: underline;
}

/* Sección del formulario */
.contact-form {
  margin-bottom: 40px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form label {
  font-weight: bold;
  margin-top: 10px;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.contact-form button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Sección de redes sociales */
.social-media {
  text-align: center;
}

.social-links a {
  margin: 0 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.social-links a:hover {
  text-decoration: underline;
}

/* Estilos para la imagen antes del "Contáctanos" */
.contact-image img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  margin-bottom: 20px;
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
  .contact-details {
    flex-direction: column; /* Alinear los detalles de contacto en columna en pantallas pequeñas */
    text-align: center;
  }

  .contact-details div {
    justify-content: center; /* Centrar los elementos dentro de cada div */
  }
}
