#rooms {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.room {
  margin-bottom: 40px;
  background-color: white; /* Color de fondo predeterminado para todas las habitaciones */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.room:nth-child(3) {
  background-color: #d8d6d6ec; /* Fondo gris claro solo para la segunda habitación */
}

.room h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}
.room-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Asegura que los contenedores de las habitaciones tengan la misma altura */
  position: relative;
}
.room-info {
  flex: 1;
  display: flex;
  flex-direction: column; /* Coloca el contenido y el botón en columnas */
  justify-content: space-between; /* Asegura que el botón esté en la parte inferior */
  margin-right: 20px;
  position: relative;
}

.room-info h4 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #555;
}

.room-info ul {
  list-style-type: none;
  padding: 0;
  color: #333;
}

.room-info li {
  margin-bottom: 10px;
}

.room-image {
  flex: 0 0 600px;
}

.room-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Añadir margen a los iconos dentro de la lista de amenidades */
.room-info li {
  display: flex;
  align-items: center;
}

.room-info li svg {
  margin-right: 12px; /* Espacio entre el ícono y el texto */
}

.reserve-btn, .reserve-btn1 {
  align-self: flex-start; /* Asegura que los botones se alineen al inicio del contenedor */
  padding: 10px 20px;
  background-color: green;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  margin-top: auto; /* Esto asegura que el botón siempre esté al final */
}

/* Responsividad */
@media (max-width: 768px) {
  .room-container {
    flex-direction: column;
    align-items: center;
  }

  .room-info {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .room-image {
    flex: 0 0 auto;
    width: 100%;
    max-width: 300px;
  }

  .reserve-btn {
    position: relative;
    transform: translateX(0) translateY(0);
    margin-top: 10px;
  }
}
