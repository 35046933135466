/* Estilos generales */
#faq {
    padding: 50px 20px;
    max-width: 1000px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-size: 36px;
    text-transform: uppercase;
  }
  
  /* Contenedor de preguntas */
  .faq-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Estilos para cada pregunta (FAQ) */
  .faq {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .faq h3 {
    margin: 0;
    font-size: 24px;
    color: #333;
    transition: all 0.3s ease;
  }
  
  .faq.active h3 {
    color: #007bff;
  }
  
  .faq p {
    margin: 10px 0 0;
    color: #666;
    font-size: 16px;
    line-height: 1.6;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }
  
  .faq.active p {
    max-height: 500px; /* Ajusta esto según el contenido */
  }
  
  .answer {
    margin-top: 10px;
    color: #555;
  }
  
  /* Efecto hover */
  .faq:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    #faq {
      padding: 20px;
    }
  
    h2 {
      font-size: 28px;
    }
  
    .faq h3 {
      font-size: 20px;
    }
  
    .faq p {
      font-size: 14px;
    }
  }
  