#Comodidades {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
  color: #333;
}

li {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

/* Estilos del carrusel */
.carousel {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  white-space: nowrap;  /* Evitar el salto de línea */
}

.carousel-item {
  display: inline-block;  /* Asegura que los items se alineen en una sola fila */
  width: 450px; /* Ajusta el tamaño según sea necesario */
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsividad */
@media (max-width: 768px) {
  .carousel-item {
    width: 100%;
  }
}
